export default class GetInTouch {
    constructor() {
        const hasCookie = document.cookie.indexOf("get_in_touch_panel=true") >= 0;
        const body = document.querySelector('body');
        const bodyW = body.clientWidth;
        const panel = document.getElementById('get-in-touch');
        const toggle = document.getElementById('get-in-touch-toggle');

        let isOpen = false;

        // Open on Page Load (and auto-close if closing timer is set)
        // if (panel.dataset.openOnPageLoad === 'true' && !hasCookie) {
        if (panel.dataset.openOnPageLoad === 'true') {
            panel.classList.add('is-open');
            isOpen = true;

            const autoCloseTimer = parseInt(panel.dataset.autoCloseTimer);

            if (autoCloseTimer) {
                setTimeout(() => {
                    panel.classList.remove('is-open');
                    isOpen = false;
                }, (autoCloseTimer * 1000));
            }
        }

        // Auto-open on Page Load
        const autoOpenTimer = parseInt(panel.dataset.autoOpenTimer);

        // if (autoOpenTimer && panel.dataset.openOnPageLoad === 'false' && !hasCookie) {
        if (autoOpenTimer && panel.dataset.openOnPageLoad === 'false') {
            setTimeout(() => {
                panel.classList.add('is-open');
                isOpen = true;
            }, (autoOpenTimer * 1000));
        }

        // Close on Page Scroll
        if (panel.dataset.closeOnPageScroll === 'true') {
            // if ((bodyW > 1024) && (window.scrollY > 100)) {
            //     panel.classList.remove('is-open');
            // }

            window.addEventListener('scroll', function () {
                const bodyW = body.clientWidth;
                const form = panel.querySelector('form');

                if (form && form.querySelector(':focus')) {
                } else {
                    if ((bodyW > 1024) && (window.scrollY > 100)) {
                        if (body.classList.contains('sticky-down')) {
                            panel.classList.remove('is-open');
                            isOpen = false;
                        }
                    }
                }
            });
        }

        // Hover
        window.addEventListener('mouseover', event => {
            const trigger = event.target;

            if (!isOpen) {
                if (trigger === toggle && panel.dataset.openOnClickOrHover === 'hover') {
                    panel.classList.add('is-open');
                    isOpen = true;
                }
            } else {
                if (trigger === toggle && panel.dataset.closeOnClickOrHover === 'hover') {
                    panel.classList.remove('is-open');
                    isOpen = false;
                }
            }
        });

        // Click
        window.addEventListener('click', event => {
            const trigger = event.target;

            // if (trigger === toggle && !hasCookie) {
            //     document.cookie = "get_in_touch_panel=true; max-age=604800; path=/";
            // }

            if (!isOpen) {
                if (trigger === toggle && panel.dataset.openOnClickOrHover === 'click') {
                    panel.classList.add('is-open');
                    isOpen = true;
                }
            } else {
                if (trigger === toggle && panel.dataset.closeOnClickOrHover === 'click') {
                    panel.classList.remove('is-open');
                    isOpen = false;
                }
            }
        });

        // Responsiveness
        if (bodyW <= 1024 && panel.classList.contains('is-open')) {
            panel.classList.remove('is-open');
            isOpen = false;
        }
    }
}